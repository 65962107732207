<template>
  <div>
    <v-col cols="12" class="primary mt-4 mx-0 pa-6 white--text">
      <v-row>
        <div class="text-uppercase font-weight-bold">session management</div>
      </v-row>
      <v-row class="">
        <div class="">
          Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim
          eius tempore natus! Neque dolore explicabo aliquid inventore
          consequuntur vitae illum ratione doloribus quo, cum in, soluta
          perferendis laboriosam qui.
        </div>
      </v-row>
    </v-col>

    <div class="pa-2 mt-3">
      <div class="d-flex">
        <v-dialog width="auto" v-model="addDialog">
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" color="primary" class="mb-2"
              ><v-icon left>mdi-plus</v-icon> new session</v-btn
            >
          </template>

          <v-card width="500">
            <v-stepper v-model="stepper">
              <v-stepper-header>
                <v-stepper-step step="1"> Session Info </v-stepper-step>
                <v-stepper-step step="2" :complete="stepper > 1">
                  Session Time
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-stepper-content step="1">
                  <v-card>
                    <v-card-text>
                      <v-autocomplete
                        v-model="addMeetingBody"
                        outlined
                        dense
                        label="Meeting Body"
                        :items="['House', 'Commitee', 'Joint Commitee']"
                      ></v-autocomplete>

                      <v-autocomplete
                        v-model="addCommitee"
                        outlined
                        dense
                        label="Commitee"
                        :items="[
                          'Commitee 1',
                          'Commitee 2',
                          'Joint Commitee 1',
                        ]"
                      ></v-autocomplete>

                      <v-autocomplete
                        label="Meeting Type"
                        v-model="addSessionType"
                        outlined
                        dense
                        :items="[
                          'Normal Session',
                          'Public Hearing',
                          'Special Seating',
                          'Adhock Session',
                        ]"
                      ></v-autocomplete>

                      <v-text-field
                        label="Title"
                        outlined
                        dense
                        v-model="addTitle"
                      ></v-text-field>

                      <v-textarea
                        label="Description"
                        outlined
                        dense
                        v-model="addDescription"
                      ></v-textarea>
                    </v-card-text>

                    <v-card-actions>
                      <v-btn color="primary" @click="addDialog = false"
                        >cancel</v-btn
                      >
                      <v-spacer></v-spacer>
                      <v-btn color="primary" @click="stepper = 2"
                        >next <v-icon right>mdi-chevron-right</v-icon></v-btn
                      >
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
                <v-stepper-content step="2">
                  <v-card>
                    <v-card-text>
                      <v-menu
                        v-model="addDateMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addDate"
                            label="Date"
                            prepend-icon="mdi-calendar"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="addDate"
                          @input="addDateMenu = false"
                        ></v-date-picker>
                      </v-menu>

                      <v-menu
                        ref="menu"
                        v-model="addTimeMenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        :return-value.sync="time"
                        transition="scale-transition"
                        offset-y
                        max-width="290px"
                        min-width="290px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="addTime"
                            label="Time"
                            prepend-icon="mdi-clock-time-four-outline"
                            readonly
                            dense
                            outlined
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-time-picker
                          v-if="addTimeMenu"
                          v-model="addTime"
                          full-width
                          @click:minute="$refs.menu.save(time)"
                        ></v-time-picker>
                      </v-menu>
                    </v-card-text>
                    <v-card-actions>
                      <v-btn @click="stepper = 1" color="primary">
                        <v-icon left>mdi-chevron-left</v-icon> previous
                      </v-btn>
                      <v-spacer></v-spacer>

                      <v-btn color="primary"> create session </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-stepper-content>
              </v-stepper-items>
            </v-stepper>
          </v-card>
        </v-dialog>

        <v-spacer></v-spacer>
        <v-spacer></v-spacer>

        <v-text-field
          v-model="search"
          label="Search"
          outlined
          dense
          append-icon="mdi-magnify"
        ></v-text-field>
      </div>
      <v-card>
        <v-data-table
          :search="search"
          :items="items"
          :headers="headers"
          class="text-capitalize"
        >
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex">
              <v-btn icon @click="editItem(item)">
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
              <v-btn icon @click="deleteDialog = true">
                <v-icon>mdi-delete</v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:[`item.date`]="{ item }">
            {{ item.date | moment("ddd, MMMM Do YYYY") }}
          </template>
          <template v-slot:[`item.time`]="{ item }">
            {{ item.time | moment("calendar") }}
          </template>
        </v-data-table>

        <v-dialog width="auto" v-model="editDialog">
          <v-card width="500">
            <v-card-title>
              <p class="text-uppercase mx-auto">edit session</p>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text class="mt-2">
              <v-autocomplete
                outlined
                dense
                prepend-icon="mdi-account-group"
                label="Meeting Body"
                :items="['House', 'Commitee', 'Joint Commitee']"
                v-model="meetingBody"
              ></v-autocomplete>

              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                transition="scale-transition"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="date"
                    label="Date"
                    prepend-icon="mdi-calendar"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="date"
                  @input="dateMenu = false"
                ></v-date-picker>
              </v-menu>

              <v-menu
                ref="menu"
                v-model="timeMenu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="time"
                    label="Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    dense
                    outlined
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="timeMenu"
                  v-model="time"
                  full-width
                  @click:minute="$refs.menu.save(time)"
                ></v-time-picker>
              </v-menu>

              <v-textarea
                label="Session Description"
                v-model="sessionDescription"
                prepend-icon="mdi-text"
                outlined
              ></v-textarea>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" @click="editDialog = false">
                cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="primary"> save </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <v-dialog v-model="deleteDialog" width="auto">
          <v-card width="500">
            <v-card-title>
              <p class="mx-auto text-uppercase">delete session</p>
            </v-card-title>

            <v-divider></v-divider>

            <v-card-text class="mt-2">
              <p class="text-subtitle-1 black--text text-center">
                Are you sure you want to delete this session?
              </p>
            </v-card-text>

            <v-card-actions>
              <v-btn color="primary" @click="deleteDialog = false">
                cancel
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn color="error"> delete </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-card>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      addDialog: false,
      editDialog: false,
      deleteDialog: false,
      meetingBody: "",
      dateMenu: false,
      date: "",
      timeMenu: false,
      time: "",
      addDate: new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      addDateMenu: false,
      addTime: new Date(new Date() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(11, 5),
      addTimeMenu: false,
      sessionDescription: "",
      addMeetingBody: "",
      addCommitee: "",
      addSessionType: "",
      addTitle: "",
      addDescription: "",
      search: "",
      headers: [
        {
          text: "meeting body",
          value: "body",
          class: "text-uppercase black--text",
        },
        {
          text: "title",
          value: "title",
          class: "text-uppercase black--text",
        },
        {
          text: "date",
          value: "date",
          class: "text-uppercase black--text",
        },
        {
          text: "time",
          value: "time",
          class: "text-uppercase black--text",
        },
        {
          text: "description",
          value: "description",
          class: "text-uppercase black--text",
        },
        {
          text: "actions",
          value: "actions",
          class: "text-uppercase black--text",
        },
      ],
      items: [
        {
          body: "House",
          title: "budget",
          date: new Date(),
          time: new Date(),
          description:
            "A house session to disuss the budget for this financial year",
        },
        {
          body: "House",
          title: "budget",
          date: new Date(),
          time: new Date(),
          description:
            "A house session to disuss the budget for this financial year",
        },
        {
          body: "House",
          title: "budget",
          date: new Date(),
          time: new Date(),
          description:
            "A house session to disuss the budget for this financial year",
        },
        {
          body: "House",
          title: "budget",
          date: new Date(),
          time: new Date(),
          description:
            "A house session to disuss the budget for this financial year",
        },
        {
          body: "House",
          title: "budget",
          date: new Date(),
          time: new Date(),
          description:
            "A house session to disuss the budget for this financial year",
        },
      ],
      stepper: 1,
    };
  },
  methods: {
    editItem(item) {
      this.meetingBody = item.body;
      this.time = new Date(item.date - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(11, 5);
      this.date = new Date(item.date - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10);
      this.sessionDescription = item.description;

      this.editDialog = true;
    },
  },
};
</script>

<style></style>
