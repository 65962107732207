var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-col',{staticClass:"primary mt-4 mx-0 pa-6 white--text",attrs:{"cols":"12"}},[_c('v-row',[_c('div',{staticClass:"text-uppercase font-weight-bold"},[_vm._v("session management")])]),_c('v-row',{},[_c('div',{},[_vm._v(" Lorem ipsum dolor sit, amet consectetur adipisicing elit. Aliquid enim eius tempore natus! Neque dolore explicabo aliquid inventore consequuntur vitae illum ratione doloribus quo, cum in, soluta perferendis laboriosam qui. ")])])],1),_c('div',{staticClass:"pa-2 mt-3"},[_c('div',{staticClass:"d-flex"},[_c('v-dialog',{attrs:{"width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"mb-2",attrs:{"color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" new session")],1)]}}]),model:{value:(_vm.addDialog),callback:function ($$v) {_vm.addDialog=$$v},expression:"addDialog"}},[_c('v-card',{attrs:{"width":"500"}},[_c('v-stepper',{model:{value:(_vm.stepper),callback:function ($$v) {_vm.stepper=$$v},expression:"stepper"}},[_c('v-stepper-header',[_c('v-stepper-step',{attrs:{"step":"1"}},[_vm._v(" Session Info ")]),_c('v-stepper-step',{attrs:{"step":"2","complete":_vm.stepper > 1}},[_vm._v(" Session Time ")])],1),_c('v-stepper-items',[_c('v-stepper-content',{attrs:{"step":"1"}},[_c('v-card',[_c('v-card-text',[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Meeting Body","items":['House', 'Commitee', 'Joint Commitee']},model:{value:(_vm.addMeetingBody),callback:function ($$v) {_vm.addMeetingBody=$$v},expression:"addMeetingBody"}}),_c('v-autocomplete',{attrs:{"outlined":"","dense":"","label":"Commitee","items":[
                        'Commitee 1',
                        'Commitee 2',
                        'Joint Commitee 1' ]},model:{value:(_vm.addCommitee),callback:function ($$v) {_vm.addCommitee=$$v},expression:"addCommitee"}}),_c('v-autocomplete',{attrs:{"label":"Meeting Type","outlined":"","dense":"","items":[
                        'Normal Session',
                        'Public Hearing',
                        'Special Seating',
                        'Adhock Session' ]},model:{value:(_vm.addSessionType),callback:function ($$v) {_vm.addSessionType=$$v},expression:"addSessionType"}}),_c('v-text-field',{attrs:{"label":"Title","outlined":"","dense":""},model:{value:(_vm.addTitle),callback:function ($$v) {_vm.addTitle=$$v},expression:"addTitle"}}),_c('v-textarea',{attrs:{"label":"Description","outlined":"","dense":""},model:{value:(_vm.addDescription),callback:function ($$v) {_vm.addDescription=$$v},expression:"addDescription"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.addDialog = false}}},[_vm._v("cancel")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper = 2}}},[_vm._v("next "),_c('v-icon',{attrs:{"right":""}},[_vm._v("mdi-chevron-right")])],1)],1)],1)],1),_c('v-stepper-content',{attrs:{"step":"2"}},[_c('v-card',[_c('v-card-text',[_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":"","dense":"","outlined":""},model:{value:(_vm.addDate),callback:function ($$v) {_vm.addDate=$$v},expression:"addDate"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.addDateMenu),callback:function ($$v) {_vm.addDateMenu=$$v},expression:"addDateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.addDateMenu = false}},model:{value:(_vm.addDate),callback:function ($$v) {_vm.addDate=$$v},expression:"addDate"}})],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","dense":"","outlined":""},model:{value:(_vm.addTime),callback:function ($$v) {_vm.addTime=$$v},expression:"addTime"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.addTimeMenu),callback:function ($$v) {_vm.addTimeMenu=$$v},expression:"addTimeMenu"}},[(_vm.addTimeMenu)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.addTime),callback:function ($$v) {_vm.addTime=$$v},expression:"addTime"}}):_vm._e()],1)],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.stepper = 1}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-chevron-left")]),_vm._v(" previous ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" create session ")])],1)],1)],1)],1)],1)],1)],1),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"label":"Search","outlined":"","dense":"","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-card',[_c('v-data-table',{staticClass:"text-capitalize",attrs:{"search":_vm.search,"items":_vm.items,"headers":_vm.headers},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
                      var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.deleteDialog = true}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)]}},{key:"item.date",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.date,"ddd, MMMM Do YYYY"))+" ")]}},{key:"item.time",fn:function(ref){
                      var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("moment")(item.time,"calendar"))+" ")]}}],null,true)}),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-title',[_c('p',{staticClass:"text-uppercase mx-auto"},[_vm._v("edit session")])]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_c('v-autocomplete',{attrs:{"outlined":"","dense":"","prepend-icon":"mdi-account-group","label":"Meeting Body","items":['House', 'Commitee', 'Joint Commitee']},model:{value:(_vm.meetingBody),callback:function ($$v) {_vm.meetingBody=$$v},expression:"meetingBody"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date","prepend-icon":"mdi-calendar","readonly":"","dense":"","outlined":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.dateMenu),callback:function ($$v) {_vm.dateMenu=$$v},expression:"dateMenu"}},[_c('v-date-picker',{on:{"input":function($event){_vm.dateMenu = false}},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}})],1),_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"nudge-right":40,"return-value":_vm.time,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},on:{"update:returnValue":function($event){_vm.time=$event},"update:return-value":function($event){_vm.time=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Time","prepend-icon":"mdi-clock-time-four-outline","readonly":"","dense":"","outlined":""},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.timeMenu),callback:function ($$v) {_vm.timeMenu=$$v},expression:"timeMenu"}},[(_vm.timeMenu)?_c('v-time-picker',{attrs:{"full-width":""},on:{"click:minute":function($event){return _vm.$refs.menu.save(_vm.time)}},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}):_vm._e()],1),_c('v-textarea',{attrs:{"label":"Session Description","prepend-icon":"mdi-text","outlined":""},model:{value:(_vm.sessionDescription),callback:function ($$v) {_vm.sessionDescription=$$v},expression:"sessionDescription"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.editDialog = false}}},[_vm._v(" cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"}},[_vm._v(" save ")])],1)],1)],1),_c('v-dialog',{attrs:{"width":"auto"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',{attrs:{"width":"500"}},[_c('v-card-title',[_c('p',{staticClass:"mx-auto text-uppercase"},[_vm._v("delete session")])]),_c('v-divider'),_c('v-card-text',{staticClass:"mt-2"},[_c('p',{staticClass:"text-subtitle-1 black--text text-center"},[_vm._v(" Are you sure you want to delete this session? ")])]),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){_vm.deleteDialog = false}}},[_vm._v(" cancel ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error"}},[_vm._v(" delete ")])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }